












export class LocalNavigationAdapter  {
  

  

  constructor(options) {
    this.options = Object.assign(
      {
        navigationGroups: [],
        navigationItems: [],
      },
      options
    );
  }

  onContext(context) {
    this.context = context;

    this.init();
  }

  onUser(user) {
    this.init();
  }

   async init() {
    this.context.setNavigationGroups(this.options.navigationGroups);
    this.context.setNavigationItems(this.options.navigationItems);
    this.context.setLoading(false);
  }

  async createNavigationGroup(input) {
    // todo

    return "";
  }

  async updateNavigationGroup(input) {
    // todo
  }

  async deleteNavigationGroup(input) {
    // todo
  }

  async createNavigationItem(input) {
    // todo

    return "";
  }

  async updateNavigationItem(input) {
    // todo
  }

  async deleteNavigationItem(input) {
    // todo
  }
}
