const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\checkmyva-dashboard\\app\\js\\checkmyva\\components\\AppGlobals.tsx";// @ts-check

import * as React from "react";

import { useTranslation, AppPortal } from "@opendash/core";
import { HeaderMenuItem } from "@opendash/ui";

export const AppGlobals = React.memo(function AppGlobals() {
  const t = useTranslation();

  return (
    React.createElement(React.Fragment, null
      , React.createElement(AppPortal, { place: "headerAfterMenuLeft", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
        , React.createElement(HeaderMenuItem, {
          onClick: () => {
            window.open(t("checkmyva:nav.guide_url"), "_blank").focus();
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
        
          , t("checkmyva:nav.guide")
        )
        , React.createElement(HeaderMenuItem, {
          onClick: () => {
            window.open(t("checkmyva:nav.faq_url"), "_blank").focus();
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
        
          , t("checkmyva:nav.faq")
        )
      )
    )
  );
});
