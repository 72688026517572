 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {
  Aggregation,









} from "@opendash/core";

import { validateConnection, fetchValues } from "../extension";


function setResolution(
  start,
  end,
  mode,
  maxValues,
  values
) {
  const result = [];

  if (values.length <= maxValues) {
    return values;
  }

  const bucketSize = Math.ceil(values.length / (maxValues / 2));

  if (mode === "minmax") {
    let count = 0;
    let maxVal = Number.NEGATIVE_INFINITY;
    let minVal = Number.POSITIVE_INFINITY;
    let max = null;
    let min = null;

    for (let val of values) {
      if (count == bucketSize) {
        if (max && min) {
          if (max.date > min.date) {
            result.push(min);
            result.push(max);
          } else {
            result.push(max);
            result.push(min);
          }
        }
        count = 0;
        maxVal = Number.NEGATIVE_INFINITY;
        minVal = Number.POSITIVE_INFINITY;
        max = null;
        min = null;
      }

      if (val.value > maxVal) {
        max = val;
        maxVal = val.value;
      }
      if (val.value <= minVal) {
        min = val;
        minVal = val.value;
      }
      count++;
    }
  }

  return result;
}

const valueTypes = [
  {
    name: "Frage",
    type: "String",
    unit: "String",
  },
  {
    name: "Antwort",
    type: "String",
    unit: "String",
  },
  {
    name: "Gerät",
    type: "String",
    unit: "String",
  },
];

export class DataAdapter  {
  
  

   __init2() {this.categories = {}}

   __init3() {this.alexaData = []}
   __init4() {this.googleData = []}

  constructor() {;DataAdapter.prototype.__init2.call(this);DataAdapter.prototype.__init3.call(this);DataAdapter.prototype.__init4.call(this);
    window.addEventListener("storage", ({ key, newValue }) => {
      if (key === "checkmyva/categories") {
        this.__initCategories();
      }
    });
  }

  onContext(context) {
    this.context = context;

    this.__init();
  }

  onUser(user) {}
  onSource(source, descendents) {}

  async fetchValues(
    item,
    options = {}
  ) {
    try {
      if (
        _optionalChain([options, 'optionalAccess', _ => _.aggregation]) ||
        _optionalChain([options, 'optionalAccess', _2 => _2.aggregationOperation]) ||
        _optionalChain([options, 'optionalAccess', _3 => _3.aggregationSplits])
      ) {
        throw new Error(
          "DataAdapter.fetchValues() does not support aggregation"
        );
      }

      return await this.__getData(
        item.source,
        item.id,
        options.start,
        options.end
      );
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async fetchDimensionValues(
    item,
    dimension,
    options = {}
  ) {
    try {
      const data = await this.__getData(
        item.source,
        item.id,
        options.start,
        options.end
      );

      if (options.limit && options.limit < data.length) {
        data.splice(0, data.length - options.limit);
      }

      let result = data.map((x) => ({
        date: x.date,
        value: x.value[dimension],
      }));

      if (options.resolution) {
        result = setResolution(
          options.start,
          options.end,
          "minmax",
          options.resolutionMaxValues,
          result
        );
      }

      if (options.aggregation) {
        result = Aggregation.aggregate(
          options.aggregationOperation,
          options.aggregationPots,
          result
        );
      }

      return result;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async update(item) {}

   async __init() {
    try {
      if (!this.context) {
        return;
      }

      if (!(await validateConnection())) {
        await this.context.setLoading(false);
        return;
      }

      await this.context.reset();

      this.alexaData = await fetchValues(
        "checkmyva.alexa.takeout",
        0,
        Date.now()
      );

      this.googleData = await fetchValues(
        "checkmyva.google.takeout",
        0,
        Date.now()
      );

      // if (this.alexaData.length > 0) {
      await this.context.setItem({
        source: "checkmyva",
        id: "checkmyva.alexa.takeout",
        name: "Alexa Daten",
        meta: {
          color: "#33c5f3",
        },
        valueTypes,
      });
      // }

      // if (this.googleData.length > 0) {
      await this.context.setItem({
        source: "checkmyva",
        id: "checkmyva.google.takeout",
        name: "Google Daten",
        meta: {
          color: "#f44336",
        },
        valueTypes,
      });
      // }

      await this.__initCategories();

      await this.context.setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

   async __initCategories() {
    try {
      if (!this.context) {
        return;
      }

      const previousCategories = this.categories || {};

      try {
        this.categories = JSON.parse(
          window.localStorage.getItem("checkmyva/categories")
        );
      } catch (e) {
        this.categories = {};
      }

      for (const key of Object.keys(previousCategories)) {
        try {
          if (!this.categories[key]) {
            await this.context.removeItem(
              // @ts-ignore
              {
                source: "checkmyva",
                id: "checkmyva-categories." + key,
              }
            );
          }
        } catch (error) {
          console.error("__initCategories", error);
        }
      }

      this.context.setItems(
        Object.entries(this.categories).map(
          ([key, { name, words, color }]) => ({
            source: "checkmyva",
            id: "checkmyva-categories." + key,
            name: name,
            meta: {
              checkmyvaCategory: true,
              checkmyvaCategoryWords: words,
              color: color,
            },
            valueTypes,
          })
        )
      );
    } catch (error) {
      console.error(error);
    }
  }

   async __getData(
    source,
    id,
    start,
    end
  ) {
    try {
      if (id === "checkmyva.alexa.takeout") {
        // return await this.__getDataFromExtension(id, start, end);

        return this.alexaData.filter((x) => x.date >= start && x.date <= end);
      }

      if (id === "checkmyva.google.takeout") {
        // return await this.__getDataFromExtension(id, start, end);

        return this.googleData.filter((x) => x.date >= start && x.date <= end);
      }

      if (id.startsWith("checkmyva-categories.")) {
        return await this.__getDataForCategory(id, start, end);
      }

      return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  }
   async __getDataForCategory(
    id,
    start,
    end
  ) {
    if (!this.context) {
      return;
    }

    id = id.replace("checkmyva-categories.", "");

    const meta = this.categories[id];

    if (!meta) {
      return [];
    }

    const raw = await Promise.all([
      this.__getData(null, "checkmyva.alexa.takeout", start, end),
      this.__getData(null, "checkmyva.google.takeout", start, end),
    ]);

    const result = [];

    for (const source of raw) {
      for (const row of source) {
        for (const word of meta.words) {
          const sentence = (row.value[0] || "").toLowerCase();
          if (
            sentence === word ||
            sentence.startsWith(word + " ") ||
            sentence.endsWith(" " + word) ||
            sentence.includes(" " + word + " ")
          ) {
            result.push(row);
            break;
          }
        }
      }
    }

    return result;
  }
}
