












export class LocalDashboardAdapter  {
  

  

  constructor(options = {}) {
    this.options = Object.assign(
      {
        defaultDashboards: [],
        defaultWidgets: [],
      },
      options
    );

    // this.setDashboards([]);
    // this.setWidgets([]);
  }

  onContext(context) {
    this.context = context;

    this.init();
  }

  onUser(user) {
    this.init();
  }

   async init() {
    this.context.setDashboards(await this.getDashboards());
    this.context.setWidgets(await this.getWidgets());
    this.context.setLoading(false);
  }

  async createDashboard(input) {
    // console.log("DashboardAdapter.createDashboard()", JSON.stringify(input));
    try {
      const id = uuid();

      const dashboard = {
        id,
        widgets: input.widgets || [],
        ...input,
      };

      const dashboards = await this.getDashboards();

      // console.log(dashboards);

      await this.setDashboards([...dashboards, dashboard]);

      this.context.updateDashboard(id, dashboard);

      return dashboard.id;
    } catch (error) {
      console.error(error);
    }
  }

  async updateDashboard(input) {
    // console.log("DashboardAdapter.updateDashboard()", JSON.stringify(input));
    try {
      const dashboards = await this.getDashboards();

      await this.setDashboards([
        ...dashboards.filter((o) => o.id !== input.id),
        input,
      ]);

      this.context.updateDashboard(input.id, input);
    } catch (error) {
      console.error(error);
    }
  }

  async deleteDashboard(input) {
    // console.log("DashboardAdapter.deleteDashboard()", JSON.stringify(input));
    try {
      const dashboards = await this.getDashboards();

      const dashboardsFiltered = dashboards.filter(
        (dashboard) => dashboard !== input.id
      );

      await this.setDashboards(dashboardsFiltered);

      this.context.updateDashboard(input.id, undefined);
    } catch (error) {
      console.error(error);
    }
  }

  async createWidget(input) {
    // console.log("DashboardAdapter.createWidget()", JSON.stringify(input));
    try {
      const id = uuid();

      const widget = {
        id,
        ...input,
      };

      const widgets = await this.getWidgets();

      await this.setWidgets([...widgets, widget]);

      this.context.updateWidget(id, widget);

      return widget.id;
    } catch (error) {
      console.error(error);
    }
  }

  async updateWidget(input) {
    // console.log("DashboardAdapter.updateWidget()", JSON.stringify(input));
    try {
      const widgets = await this.getWidgets();

      await this.setWidgets([
        ...widgets.filter((o) => o.id !== input.id),
        input,
      ]);

      this.context.updateWidget(input.id, input);
    } catch (error) {
      console.error(error);
    }
  }

  async deleteWidget(input) {
    // console.log("DashboardAdapter.deleteWidget()", JSON.stringify(input));
    try {
      const widgets = await this.getWidgets();

      const widgetsFiltered = widgets.filter((widget) => widget !== input.id);

      await this.setWidgets(widgetsFiltered);

      this.context.updateWidget(input.id, undefined);
    } catch (error) {
      console.error(error);
    }
  }

   async getDashboards() {
    const jsonString = window.localStorage.getItem("opendashLocalDashboards");

    // console.log("getDashboards", jsonString);

    return JSON.parse(jsonString) || this.options.defaultDashboards;
  }

   async setDashboards(dashboards) {
    // console.log("setDashboards", JSON.stringify(dashboards, null, 2));
    return window.localStorage.setItem(
      "opendashLocalDashboards",
      JSON.stringify(dashboards)
    );
  }

   async getWidgets() {
    const jsonString = window.localStorage.getItem("opendashLocalWidgets");

    // console.log("getWidgets", jsonString);

    return JSON.parse(jsonString) || this.options.defaultWidgets;
  }

   async setWidgets(widgets) {
    // console.log("setWidgets", JSON.stringify(widgets, null, 2));
    return window.localStorage.setItem(
      "opendashLocalWidgets",
      JSON.stringify(widgets)
    );
  }
}

function uuid(a) {
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : // @ts-ignore
      ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);
}
