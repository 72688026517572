










export class LocalSourceAdapter  {
  

  

  constructor(options) {
    this.options = options;
  }

  onContext(context) {
    this.context = context;

    this.init();
  }

  onUser(user) {
    this.init();
  }

   async init() {
    this.context.setSources(this.options.sources);
    this.context.setLoading(false);
  }
}
